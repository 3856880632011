.promo {
  height: 75vh;
  min-height:780px;
  width: 100%;
  background-image: url(/wp-content/themes/tourlabs-stanley/assets/images/promo-home.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  margin-top:0;
  
  &.overlay{
    &::before{
      content:"";
      display:block;
      width:100%;
      height:100%;
      position:absolute;
      left:0;
      top:0;
      background-color: rgba(0,0,0,0.3);
    }
  }
 
  
  @include breakpoint(small){
    min-height:500px;
  }
  &.promo-subpage{
    border-bottom:33px solid #b7b7b7;
    margin-bottom:10px;
    &::after{
      content:"";
      display:block;
      position:absolute;
      left:0;
      bottom:-39px;
      height:1px;
      width:100%;
      background-color:#e1e1e1;
    }
  }
  &.promo-venues {
    background-image: url(/wp-content/themes/tourlabs-stanley/assets/images/promo-venues.jpg);
    z-index: 1;
  }

  &.promo-weddings {
    background-image: url(/wp-content/themes/tourlabs-stanley/assets/images/promo-weddings.jpg);
    z-index: 1;
  }

  .grid-container {
    height: 100%;

    .grid-x {
      height: 100%;

      .cell {
        height: 100%;
        position: relative;

        .promo-wrapper {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          width: 100%;
          color: $stanley-white;

          h2 {
            font-size: 62px;
            font-family: renata, sans-serif;
            font-weight: 400;
            font-style: normal;
            text-transform: none;
            margin-bottom: 0;
            @include breakpoint(medium) {
              font-size: 52px
            }
            @include breakpoint(small) {
              font-size: 32px
            }
          }

          h1 {
            font-size: 82px;
            font-weight: 300;
            letter-spacing: 20px;
            line-height: 1;
            @include breakpoint(medium) {
              font-size: 62px
            }
            @include breakpoint(small) {
              font-size: 38px;
              letter-spacing: 10px;
            }
          }

          img {
            transition: transform 0.2s ease-in;
            transform: scale(0.9);
            cursor: pointer;

            &:hover {
              transform: scale(1);
            }
          }
        }

      }
    }
  }
}

.content-nav {
  background: $stanley-grey;
  position: relative;
  z-index: 1;

  &.content-nav-subpage {
    background: none;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height:50px;
  
   
    .grid-container {
      .grid-x {
        .cell {  
          color: $stanley-white;
     
          p {
            margin-bottom: 0;

           
          }

          a {
            display: inline-block;
            font-weight: 400;
            height: auto;
            padding: 0 10px;
            opacity:1;
            transition: all 0.3s;
            &:hover{
              opacity:0.6;
              transition: all 0.3s;
              color:#fff;
            }
            &:focus{
              color:#fff;
            }
          }

          ul,ol,li{list-style:none;}
          ul{
            margin: 0 0 0 0;
          }
          ul li{
            display:inline-block;
          }

          .subnav-menu{
            li:not(:last-child){
              &::after{
                content: "|";
              }
            }
          }
          .subnav-left{
            text-align: right;
            padding-right: 75px;

            @include breakpoint(small){
              padding-right:0;
              text-align:center;
              height: 30px;
            }
            
          }
          .subnav-right{
            text-align: left;
            padding-left: 75px;
            @include breakpoint(small){
              padding-left:0;
              text-align:center;
              height: 30px;
            }
          }
          .subpage-icon-wrap{
            display:flex;
            justify-content: center;
            align-items: center;
            position:absolute;
            left: 50%;
            top:50%;
            transform: translate(-50%, -50%);
            z-index:1;
          
            .subpage-icon{

              img{
                padding:0 0 0 0;
              }
              
            }
          }
      
        }
      }
    }
  }

  &.content-nav-background-only {
    height: 33px;
    padding-bottom: 0;

    @include breakpoint(small) {
      height: auto;
      min-height: 50px;

      p {
        text-align: center;
        color: $stanley-white;
        padding-top: 13px;

        img {
          position: absolute;
          top: -45px;
          left: 0;
          right: 0;
          margin: 0 auto;
          z-index: 100;
        }
      }
    }
  }



  .grid-container {
    .grid-x {
      .cell {
        &.content-nav-middle-cell {
          border-left: 1px solid #fff;
          border-right: 1px solid #fff;
        }

        a {
          height: 74px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          font-family: 'TT Norms', sans-serif;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 2px;
          position: relative;
          @include breakpoint(small) {
            font-size: 14px;
            height: 50px;
          }

          img {
            margin-right: 15px;

            @include breakpoint(small) {
              position: absolute;
              top: -30px;
              max-width:25px;
            
            }
          }
        }
      }
    }
  }
}
