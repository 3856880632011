@font-face {
  font-family: "TT Norms";
  src: url(../fonts/TTNorms-Regular.woff) format("woff"),
    url(../fonts/TTNorms-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "TT Norms";
  src: url(../fonts/TTNorms-Bold.woff) format("woff"),
    url(../fonts/TTNorms-Bold.ttf) format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "TT Norms";
  src: url(../fonts/TTNorms-Light.woff) format("woff"),
    url(../fonts/TTNorms-Light.ttf) format("truetype");
  font-weight: 300;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "TT Norms", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.3rem;
  font-variant: normal;

  &.cursive {
    font-family: renata, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
  }
}

a {
  color: #fff;
  transition: all 0.2s ease-in;
}
a:hover {
  color: #666;
}
a:focus {
  color: #666;
  outline: none;
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}