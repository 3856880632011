.top-nav-wrapper {
  background: $stanley-lightgrey;
  .grid-container{
    @include breakpoint(small){
      padding-left:0;
      padding-right:0;
    }
  }
  ul {
    list-style-type: none;
    display: inline-block;
    margin: 0;

    li {
      display: inline-block;
      border-right: 2px solid #fff;
      padding: 5px 40px;

      @include breakpoint(medium){
        &:nth-last-child(2) {
          border: none;
        }
      }
      @include breakpoint(small) {
        padding: 5px 10px;
      }

      &:last-child {
        border: none;
      }



      a {
        color: $stanley-black;
        font-size: 11px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }    
    }
  }
}

.header-nav-wrapper {
  position: relative;
  height: 125px;
  background: $stanley-white;

  
  .grid-container {
    height: 100%;

    .grid-x {
      height: 100%;

      .cell {
        height: 100%;

        ul {
          list-style-type: none;
          display: inline-block;
          margin: 0;
          top: 37%;
          position: relative;
          
          li {
            display: inline-block;
            padding: 10px 20px;

            @include breakpoint(medium){
              padding: 10px 10px;
            }

            a {
              color: $stanley-black;
              text-transform: uppercase;
              font-size: 16px;
              font-family: 'TT Norms', sans-serif;
              letter-spacing: 2px;
            }
          }
        }

        .header-nav-left-cell {
          text-align: right;
          padding-right: 75px;
          @include breakpoint(medium){
            padding-right: 55px;
          }
        }
        
        .header-nav-right-cell {
          text-align: left;
          padding-left: 75px;

          @include breakpoint(medium){
            padding-left: 55px;
          }
        }

        .header-logo {
          width: 144px;
          height: 162px;
          position: absolute;
          bottom: -63px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          z-index:2;
          transition:all 0.5s;

          &:hover {
            .logo-image, 
            .logo-text{
              img{
                transform: scale(1.03);
              }
            }
          }

         img{
           width:100%;
         }

         .logo-image{
           width:95px;
           margin: 27px auto;
           position: relative;
           z-index: 1;
           transition:width 0.7s, margin 0.7s;
       
         }
         .logo-text{
          transform:translateY(0%);
          opacity:1;
          transition:transform 1.5s, opacity 1s;
        }
        
      }

      }
    }
  }

  &.fixed-header-nav{
    position: fixed;
    left:0;
    top:0;
    width: 100%;
    z-index: 100;
    height:100px;
    

    .grid-container .grid-x .cell ul{
      top:33%;
    }

 
    .grid-container .grid-x .cell .header-logo{
      bottom:0;
      transition:bottom 0.5s;
      height:100px;
      margin-top:20px;
     
      .logo-image{
        width:60px;
        margin: 20px auto;
      }

      .logo-text{
        transform:translateY(-300%);
        z-index:0;
        opacity:0;
        transition:transform 1.5s, opacity 1s;
      }

    }
  }
}



@media only screen and (max-width: 800px) {
  .header-nav-wrapper{
    height:90px;
  }
  .header-nav-right-cell,
  .header-nav-left-cell{
    display:none;
  }
  
  .header-separator-line{
    top: 125px;
  }
}