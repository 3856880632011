// body {
//   &.admin-bar {
//     section {
//       &.header {
//         .fixed-header-nav {
//           margin-top: 32px;
//         }
//       }
//     }

//     .fixed-line{
//       margin-top: 32px;
//     }
//   }
// }

*{
  font-variant-ligatures:none;
}

.page-content {
  position: relative;
}

.nowrap {
  white-space: nowrap;
}

@each $color, $colorVal in $colors {
  .bg-#{$color} {
    background-color: $colorVal;
  }
  .color-#{$color} {
    color: $colorVal;
  }
}

.padding-small {
  padding-top: 40px;
  padding-bottom: 40px;
}
.padding-small-top-medium-bottom {
  padding-top: 40px;
  padding-bottom: 80px;
}

.padding-normal,
.padding-medium {
  padding-top: 80px;
  padding-bottom: 80px;
}
.padding-medium-bottom {
  padding-top: 0;
  padding-bottom: 80px;
}
.padding-normal-no-bottom,
.padding-medium-no-bottom {
  padding-top: 80px;
  padding-bottom: 0;
 
}
.padding-medium-top-small-bottom {
  padding-top: 80px;
  padding-bottom: 40px;
}

.move-down-70 {
  position: relative;
  z-index: 10;
  margin-bottom: -70px;

  &.padding-medium-no-bottom{
    @include breakpoint(small){
      padding-bottom:80px;
    }
  }
}

.btn-lines-wrapper {
  border: 1px solid $stanley-black;
  padding: 5px;
  display: inline-block;
  margin-bottom: 50px;

  &:hover {
    background: $stanley-white;
    .btn-lines {
      background: $stanley-mercury;
    }  
  }

  .btn-lines {
    padding: 12px 50px;
    display: inline-block;
    border: 1px solid $stanley-black;
    text-transform: uppercase;
    color: $stanley-black;
    font-size: 14px;
    font-family: "TT Norms", sans-serif;
    letter-spacing: 2px;
    background: $stanley-white;
    transition: all 0.2s ease-in-out;
  }
}

.vert-align {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.link-gallery {
  position: relative;
  color: $stanley-black;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 10px;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    height: 1px;
    background: #000;
    top: 50%;
    left: -70px;
    width: 60px;
    opacity: .3;
  }

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    background: #000;
    top: 50%;
    right: -70px;
    width: 60px;
    opacity: .3;
  }

  img {
    margin-top: -4px;
    margin-right: 2px;
  }
}

.push-up-5 {
  margin-bottom: 5px;
}

/* Cookie Notice */

.cookie-notice-container p.cookie-title {
  font-weight: 600;
  letter-spacing: 1.2px;
  margin-bottom: 5px;
}

.cookie-notice-container p {
  font-weight: 200;
  font-size: 16px;
}

.cookie-notice-container .button {
  margin-bottom: 0;
  color: #9c302e;
  background: #fff;
  border-radius: 8px;
  text-decoration: none;
  border-color: #9c302e;
}

.list-with-no-style ul li{
  list-style:none;
}

/* FAQ */
.single-accordion{
  width:80%;
  text-align:center;
  margin:0 auto;
  font-size:20px;
  color:#636464;
  padding:50px 0;
  
  @include breakpoint(small){
    width:90%;
    font-size:15px;
  }
  .single-row{
    padding:20px 0 80px;
    position:relative;
    z-index:12; 

    @include breakpoint(small){
      padding-bottom:40px;
    }
    
    &.opened{
      padding-bottom:20px;
    }

  

    .faq-questions{
      width:100%;
      max-width:880px;
      margin:0 auto;
      background-color:#fff;
      z-index:10;
      position: relative;
      padding:7px;
 
      
      .gray_texture_bg{
        position:absolute;
        top:-23px;
        left:50%;
        transform:translateX(-50%); 
      }

      .blue_texture_bg_left{
        position:absolute;
        left:-21px;
        top: 50px;
      
        img{
          height:110px;
        }
      }

      .blue_texture_bg_top{
        position:absolute;
        left:42px;
        top: -23px;
        img{
          width:160px;
        }
      }
  

      p{
        border:1px solid #ccc;
        padding:70px 50px;
        border-radius:1px;
        margin:0 0 0 0;
        
        @include breakpoint(small){
          padding:40px 20px;
        }
       
      } 

      .faq-toggle-btn{
        position:absolute;
        display:block;
        bottom:-18px;
        left:50%;
        border-radius:50%;
        width:50px;
        height:50px;
        color:#c2c2c2;
        background-color:#fff;
        border:1px solid #c2c2c2;
        outline:none;
        transform: translateX(-50%);
        

        &.opened span{
          transform: rotate(180deg);
          transition: all 0.5s;
        }
      }

      .faq-toggle-btn span{
        display:block;
        transform: rotate(0);
        font-size:20px;
        transition: all 0.5s;
        margin:auto;
      }
    }

    &:first-child{
      .faq-answers{
        display:block;
      }
    }
    .faq-answers {
      width:85%;
      margin:0 auto;
      display:none;
      background-color:#f1f2f2;
      position: relative;
      top:-50px;
      @include breakpoint(small){
        width:calc(100% - 14px);
      }
    
      p{
        padding:100px 50px 70px;
        margin:0 0 0 0;
      @include breakpoint(small){
        padding:100px 30px 70px;
       
      }
      }
    }
  } /*single-row*/
} /*single-accordion*/







