.sb-slidebar{
  background-color: #ebebeb;
}

#mobilemenu {
  color: #000;  
  right: 25px;
  top: 35px;
  position: absolute;
  display: none;

  span {
    width: 40px;
    height: 40px;
    font-size: 32px;
  }

  
}

#menu-slidebars-menu .mobile-slider-contact{
  display:flex;
  align-items: center;
  border-bottom:none;
  
  &::before{
    content:"";
    display:inline-block;
    width:20px;
    height:20px;
    background:url(/wp-content/themes/tourlabs-stanley/assets/icons/icon-phone-sm.png) no-repeat center;
    background-size:12px 12px;
    margin-right:14px;
    vertical-align: middle;
  }

  &:last-child{
    &::before{
      content:"";
      display:inline-block;
      width:20px;
      height:20px;
      background:url(/wp-content/themes/tourlabs-stanley/assets/icons/icon-email-sm.png) no-repeat center;
      background-size:15px 8px;
      margin-right:14px;
      vertical-align: middle;
    }
  }
}

#closemobile {
  font-weight: 700;
  padding: 20px;
  padding-bottom: 10px;
  display: inline-block;
}
#sbrightmenu .menu {
  color: #fff;
  padding: 20px 31px;
}
#sbrightmenu .menu ul {
  list-style: none;
  margin-left: 0;
  width: 100%;
}


body.admin-bar {
  position: inherit;
}

body.admin-bar #sbrightmenu {
  margin-top: 46px;
}

#menu-slidebars-menu {
	color:#fff;
	list-style:none;
	width:100%;
	clear:both;
}
#menu-slidebars-menu > li {
  width:100%;
  border-bottom:1px solid  #ccc;

  &:nth-last-child(3){
    &::after{
      content: "";
      display:block;
      height:1px;
      width:100%;
      background-color:#ccc;
      margin:5px 0;
    }
  }
}

#menu-slidebars-menu ul.sub-menu li.current_page_item a{
  color:#921b1e;
}
#menu-slidebars-menu li a {
  color: #000;
	padding: 15px 0;
	display: block;
  position: relative;
  text-transform: uppercase;
  font-weight:500;
  letter-spacing: 2px;
  font-size:14px;
  font-family:'TT Norms', sans-serif;

  &:hover{
    color:#921b1e;
  }
}


#menu-slidebars-menu > li.menu-item-has-children a.active{
  color:#921b1e;
}

#menu-slidebars-menu > li.menu-item-has-children > a::after {
	content: " ";
	background: url(/wp-content/themes/tourlabs-stanley/assets/icons/icon-quote-arrow-right.png) center bottom no-repeat;
	position: absolute;
	right: 5px;
	top: 20px;
	transition: all 0.5s ease;
	width: 12px;
	height: 7px;
	background-size: 12px 7px;
}


#menu-slidebars-menu > li.menu-item-has-children > a.active::after {
  transform: rotate(-180deg);
  background: url(/wp-content/themes/tourlabs-stanley/assets/icons/icon-quote-arrow-right2.png) center bottom no-repeat;
  transition: all 0.25s ease;
}


#menu-slidebars-menu li.current-menu-parent .sub-menu {
  display: block;
}
#menu-slidebars-menu li .sub-menu li a {
  font-size: 14px;
  padding: 10px 0 10px 12px;
}

a#closemobile {
	width:100%;
	text-align:right;
	padding:35px 31px 0;
	display:block;
  font-family:'Open Sans', sans-serif;
}

@media only screen and (max-width: 800px) {
  #mobilemenu {
    display: block;
  }
  #menu-slidebars-menu li .sub-menu {
    display: none;
    padding:10px 0 30px;
    li {
      a{
        text-transform: capitalize;
        letter-spacing: 0.5px;
        &:hover{
          color:#921b1e;
        }
      }
    }
  }


}


