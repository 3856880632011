
/*eslint-disable*/
.row-single-image {
  .grid-x {
    .cell {
      h3 {
        margin-bottom: 0;
      }

      h2 {
        font-weight: 300;
        letter-spacing: 10px;
      }

      p {
        color: $stanley-darkgrey;
        font-size: 18px;
        letter-spacing: 2px;
        line-height: 2.5em;
        margin-bottom: 30px;
      }
    }
  }
}



.row-standard {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  
  .row-standard-content-row{
    @include breakpoint(small){
      width:90%;
      margin: 0 auto;
    }
  }
  .row-overlay-top {
    background: center bottom / cover url(/wp-content/uploads/2020/01/overlay-white-top.png) no-repeat;
    height: 113px;
    top:0;
    left:0;
    position: absolute;
    width:100%;
    z-index: 1;
    
  }

  .row-overlay-bottom {
    background: top center / cover url(/wp-content/themes/tourlabs-stanley/assets/images/row-overlay-bottom.png) no-repeat;
    height: 150px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index:1;
    @include custom_breakpoint(2000){
      height:180px;
    }
    &.grey-fade {
      background-image: url(/wp-content/themes/tourlabs-stanley/assets/images/row-overlay-top-grey.png);
      background-repeat:no-repeat;
      background-size:100% 100%;
     
      height: 130px;

      @include breakpoint(small){
        bottom:-2px;
      }
    }
  }


&.event-planners-header{
  max-width:900px;
  margin:0 auto;
}
  &.event-planners-frame{
    padding-top:60px;
    padding-bottom:60px;
    width:90%;
    margin: 0 auto;

    @include breakpoint(small){
      width:100%;
      padding-top:30px;
      padding-bottom:30px;
    }
    p{
      position: relative;
      max-width:1000px;
      margin: 0 auto;
    
      span{
        border:1px solid rgba(0,0,0,0.1);
        display:block;
        margin-bottom: 10px;
        padding:10px;
        &::before{
          content: "";
          display:block;
          position:absolute;
          right: 5%;
          top: -55px;
          background:url(/wp-content/uploads/2020/01/texture-top.png) no-repeat;
          background-size:cover;
          width:324px;
          height:84px;  
          z-index:-1;

          @include breakpoint(small){
            top: -45px;
          }

         
        }
      }
      &::before{
        content: "";
        display:block;
        position:absolute;
        left:-80px;
        top:10%;
        background:url(/wp-content/uploads/2020/01/texture-left.png) no-repeat;
        background-size:cover;
        width:136px;
        height:397px;
        z-index:-1;
        @include breakpoint(medium){
          left:-50px;
          width:70px;
          height:200px;   
        }
        @include breakpoint(small){
          left:-30px;
          width:50px;
          height:150px;
        }
      }
      &::after{
        content: "";
        display:block;
        position:absolute;
        right:-80px;
        top:20%;
        background:url(/wp-content/uploads/2020/01/texture-right.png) no-repeat;
        background-size:cover;
        width:104px;
        height:388px;
        z-index:-1;
        @include breakpoint(medium){
          right:-40px;
          width: 60px;
          height: 200px;
        
        }
        @include breakpoint(small){
          right:-25px;
          width:40px;
          height:150px;
        }
      }

      img{
        
       width:calc(100%);
       display:block;
       
      }
    }
  }

  &.top-more-down{
    .row-overlay-top{
      top: 5%;
    }
  }

  &.bkg-image-row {
    height: 800px;

  
    &.medium-height {
      height: 600px;
      @include breakpoint(small){
        height:400px;
      }
    }

    &.wedding-p2{
      background-position:center top 40px;
    }
     &.events-p2{
      background-position:center 45%;
     }
   
  }

  &.twocol-image {
    h2 {
      font-weight: 300;
      
    
    }
    p {
      color: $stanley-darkgrey;
      font-size: 18px;
      letter-spacing: 2px;
      line-height: 2.5em;
      margin-bottom: 30px;
      text-transform: uppercase;
      @include breakpoint(small){
        font-size:16px;
        line-height:30px;
        width:90%;
        margin:0 auto 40px;
      }
    }
  }

  .row-standard-header-row{
    text-align:center;
    .cell{
      width:100%;
    }
   
    h2 {
      font-weight: 300;
      letter-spacing: 5px;
    }
  }


  .grid-x {
    .cell {
      h3 {
        margin-bottom: 0.3rem;
        font-size:30px;
        font-weight:300;
        font-family:'TT Norms', sans-serif;
        letter-spacing: 5px;
        &.cursive{
          font-family:renata, sans-serif;
          letter-spacing: 0;
        }
        @include breakpoint(small){
          font-size:24px;
          margin-top:30px;
        }
      }
      h2{
        font-weight:300;
        font-size:40px;
        letter-spacing: 5px;
        @include breakpoint(small){
          font-size:28px;
        }
      }
      h5 {
        letter-spacing: 2px;
        line-height: 2.25em;
        font-weight: 300;
        font-size: 22px;
        color: $stanley-darkgrey;

        p{
          line-height: 2.25em;
        }

        @include breakpoint(small) {
          font-size: 16px;
          width:90%;
          margin:0 auto;
        }

        &::after {
          content: "";
          width: 70px;
          height: 1px;
          background: $stanley-black;
          display: block;
          margin: 0 auto;
          margin-top: 30px;
        }
      }
      h5.no-bttm-border {
        &::after{
          content:"";
          width:0;
          height:0;
          background:transparent;
          margin-top:0;
        }
        @include breakpoint(small){
          line-height:32px;
        }
      }
      h5.lowercase{
        text-transform:none;
        line-height:48px;
        font-variant-ligatures:none;

        @include breakpoint(small){
          line-height:32px;
        }
      }
    }
  }


  &.position-top{
    background-position:center top;
  }

  &.bullet-highlights{
    .grid-x .cell h5{
      font-size:18px;
      width:60%;
      margin: 0 auto;
      @include breakpoint(small){
        width:90%;
      }
    }
    .two-btns-row{
      display:flex;
      justify-content: center;
      align-items: center;
      .btn-lines-wrapper{
        margin:0 12px;
        .btn-lines{
          padding:12px 20px;
          img{
            padding-right:14px;
          }
        }
       
      }
    }
    .double-border{
      border:1px solid rgba(0,0,0, 0.2);
      display: inline-block;
      margin:36px 0;

      @include breakpoint(small){
        width:90%;
      }

      ul{
        display:inline-block;
        border:1px solid rgba(0,0,0, 0.2);
        margin:5px;
        padding:35px 70px;
        position:relative;
        background-color:#fff;
        @include breakpoint(small){
          padding:35px 20px;
        }

        li{
          list-style: none;
          padding-bottom:15px;
        }

        &::before,
        &::after{
          content: "";
          display:block;
          position:absolute;
          padding-top:100%;
          background-repeat:no-repeat;
        }

        &::before{
          background-image:url(/wp-content/uploads/2020/01/venue-highlight-left-texture.png);
          background-size:contain;
          width:100%;
          padding-top:30%;
          left:-15%;
          top:50%;
          transform:translateY(-50%);
          z-index:-1;
        }
        &::after{
          background-image:url(/wp-content/uploads/2020/01/venue-highlight-bttm-texture.png);
          background-position:right bottom;
          background-size:cover;
          width:40%;
          padding-top:30%;
          right:50px;
          bottom:-20%;
          z-index:-1;
          @include breakpoint(small){
            background-size: cover;
            width: 70%;
            padding-top: 12%;
            right: 3px;
            bottom: -6%;
          }
        }

       
      }

    }
    &.event-highlight {
      .double-border{
        ul{
          &::after{
            background-image:url(/wp-content/uploads/2020/01/event-highlight-bttm-texture.png);
            bottom: -12%;

            @include breakpoint(small){
              background-size: cover;
              width: 81%;
              padding-top: 19%;
              right: 3px;
              bottom: -5%;
            }
          }
        }
      
      }
    }
  }
  p.more-margin-bttm{
    margin-bottom:40px;
    margin-top:20px;
  }
  &.bkg-image-row-parallax {
    min-height:700px;
    padding-bottom:0 !important;
    z-index: 0;
    .jarallax {
      position: relative;
      z-index: 0;
      min-height:700px;
    }

    &.p2{
      min-height:650px;
      padding-top:0;

      .jarallax {
        position: relative;
        z-index: 0;
        min-height:650px;
        background-position:c
      
      }
    
    }

    @include breakpoint(small) {
      display: none;
    }
  }

  &.sitemap-list{
    ul{
      li{
        p{
          display:none;
        }
        a{
          color:#000;
          font-size:22px;
          line-height:42px;
          letter-spacing: 0.5px;
          display:inline-block;
          text-transform: uppercase;
        }
      }
    }
  }
  &.privacy-policy{
    h5{
      a{
        color:#921b1e;
        transition:all 0.3s;
        &:hover{
          text-decoration: underline;
          transition:all 0.3s;
        }
      }
    }
  }
}

.entry-wedding {
  background: center / cover url(/wp-content/themes/tourlabs-stanley/assets/images/row-bkg-wedding.png) no-repeat;
  height: 800px;
  &.entry-venues {
    background-image: url(/wp-content/themes/tourlabs-stanley/assets/images/row-bkg-venues.jpg);
  }
  &.entry-weddings {
    background-image: url(/wp-content/themes/tourlabs-stanley/assets/images/row-bkg-weddings.jpg);
  }
  &.show-bouquet {
    background-image: url(/wp-content/themes/tourlabs-stanley/assets/images/whats-missing.jpg);
    height: 550px;
  }
  &.show-bouquet2 {
    background-image: url(/wp-content/themes/tourlabs-stanley/assets/images/bkg-bouquet-02.jpg);
    height: 550px;
  }
  &.show-bouquet3 {
    background-image: url(/wp-content/themes/tourlabs-stanley/assets/images/bkg-bouquet-03.jpg);
    height: 550px;
  }
  &.fluid {
    padding: 0;
  }  
  .grid-x {
    height: 100%;

    .cell {
      position: relative;
      height: 100%;

      .row-overlay-top {
        background: center / cover url(/wp-content/themes/tourlabs-stanley/assets/images/row-overlay-top.png) no-repeat;
        height: 263px;
      }

      .row-overlay-bottom {
        background: top center / cover url(/wp-content/themes/tourlabs-stanley/assets/images/row-overlay-bottom.png) no-repeat;
        height: 150px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

.more-from-row {
  padding-bottom:140px;
  .cell {
    overflow: hidden;

    h3 {
      position: relative;
      display: inline-block;

      &::before {
        content: "";
        position: absolute;
        height: 1px;
        background: $stanley-black;
        top: 45%;
        width: 600px;
        right: 100%;
        margin-right: 15px;
        opacity: 0.3;
      }

      &::after {
        content: "";
        position: absolute;
        height: 1px;
        background: $stanley-black;
        top: 45%;
        width: 600px;
        left: 100%;
        margin-left: 15px;
        opacity: 0.3;
      }
    }

    .more-from-wrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      a{
        padding:0 14px;
      }
    }
  }
  
}

.row-instagram {
  h3 {
    position: relative;
    font-weight: 300;
    letter-spacing: 5px;

    &::after {
      content: "";
     display:block;
      height: 1px;
      background: #000;
 

      margin: 20px auto;
      width: 60px;
      opacity: .5;
    }
  }

  h5 {
    font-size: 18px;
    color: $stanley-darkgrey;
    font-weight: 300;
    text-transform: none;
    
  }

  .row-instagram-grid {
    margin-top: 30px;
    margin-bottom: 50px;

    .cell {
      @include breakpoint(small) {
        margin-bottom: 10px;
      }

      .instagram-image {
        padding: 5px;
        border: 1px solid rgba(0,0,0, 0.2);
        margin-left: 5px;
        margin-right: 5px;
        overflow: hidden;

        @include breakpoint(small) {
          height: 100%;
        }

        &.first-item {
          margin-left: 0;
        }

        &.last-item {
          margin-right: 0;
        }

        img {
          max-height: 270px;
          width: 100%;
        }
      }
    }
  }

  .btn-lines-wrapper{
    a{
      padding:9px 50px;
    }
    
  }
}

.row-blocks-three-cols-header {
  h3 {
    font-weight: 300;
    letter-spacing: 10px;
  }
}

.row-blocks-three-cols {
  .cell {
    .pod-content-wrapper {
      background: rgba($stanley-iron, 0.25);
      padding: 20px 15px;
      position: relative;

      h5 {
        font-weight: 400;
        letter-spacing: 2px;
      }

      p {
        font-family: "TT Norms", sans-serif;
        letter-spacing: 2px;
        padding-bottom: 40px;
        margin-bottom: 10px;
      }

      .bottom-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;

        h4 {
          margin-bottom: 0;
          position: relative;
          display: inline-block;
    
          &::before {
            content: "";
            position: absolute;
            height: 1px;
            background: #000;
            top: 40%;
            left: -70px;
            width: 60px;
            opacity: .5;
          }
        
          &::after {
            content: "";
            position: absolute;
            height: 1px;
            background: #000;
            top: 40%;
            right: -70px;
            width: 60px;
            opacity: .5;
          }
        }
      }
    }
  }
}

.row-testimonials {
  position: relative;
  background: $stanley-iron;


  .owl-carousel .owl-nav button.owl-prev {
    text-indent: -9999px;
    width: 97px;
    height: 97px;
    position: absolute;
    left: -180px;
    top: 30%;
    background: center / cover url(/wp-content/themes/tourlabs-stanley/assets/images/arrow-left.png) no-repeat;
    @include breakpoint(small){
      display:none;
    }
  }
  .owl-carousel .owl-nav button.owl-next {
    text-indent: -9999px;
    height: 97px;
    width: 97px;
    position: absolute;
    right: -180px;
    top: 30%;
    background: center / cover url(/wp-content/themes/tourlabs-stanley/assets/images/arrow-right.png) no-repeat;
    @include breakpoint(small){
      display:none;
    }
  }
  &.has-header{
    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next {
      top:0
    }
   
  }

  .owl-carousel .owl-dots  {
    display:none;
    &:focus {
      outline: 0;
    }
    @include breakpoint(small){
      display:block;
    }
  }

  .row-overlay-top {
    background: center / cover url(/wp-content/themes/tourlabs-stanley/assets/images/row-overlay-top.png) no-repeat;
    height: 263px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    &.grey-fade {
      background-image: url(/wp-content/themes/tourlabs-stanley/assets/images/row-overlay-top-grey.jpg);
    }
  }

  .row-overlay-bottom {
    background: top center / cover url(/wp-content/themes/tourlabs-stanley/assets/images/row-overlay-bottom.png) no-repeat;
    height: 150px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .row-testimonials-header {
    h3 {
      position: relative;
      font-weight: 300;
      letter-spacing: 5px;
  
      &::after {
        content: "";
        position: absolute;
        height: 1px;
        background: #000;
        bottom: -10px;
        right: 0;
        left: 0;
        margin: 0 auto;
        width: 60px;
        opacity: .5;
      }
    }
  }

  .row-testimonials-content {
    p {
      font-size: 20px;
      font-family: "TT Norms", sans-serif;
      font-weight: 300;
      @include breakpoint(small){
        font-size:16px;
        line-height: 30px;
      }
    }    
    h2{
      font-weight: 300;
      font-size: 40px;
      letter-spacing: 5px;

      @include breakpoint(small){
        width:85%;
        margin:0 auto;
        font-size:30px;
      }

      &::after{
        content:"";
        display:block;
        height:1px;
        width:70px;
        background:#000;
        margin:20px auto;
      }
    }
    h5{
      font-size: 20px;
      font-family: "TT Norms",sans-serif;
      font-weight: 300;
      text-transform: none;
      line-height: 40px;
      font-variant-ligatures:none;
      @include breakpoint(small){
        width:85%;
        margin:0 auto 40px;
        font-size:16px;
        line-height: 30px;
        
      }

      ul{
        margin: 0 0;
        display: inline-block;
        // list-style-position: inside;
        text-align: left;
        margin-left:60px;
      }
    }


    h3 {
      font-size: 32px;
      font-family: renata, sans-serif;
      text-transform: none;
      font-weight: 400;
      @include breakpoint(small){
       font-size:26px;
        
      }
    }
  }
}

.row-venues {
  .row-venues-header {
    h3 {
      font-family: renata, sans-serif;
      position: relative;
      font-weight: 400;
      letter-spacing: 5px;
      text-transform: none;
      margin-bottom: 0;
    }
    h2 {
      font-weight: 300;
      letter-spacing: 5px;
    }
  }

  .row-venues-content {
    .cell {
      position: relative;

      h4 {
        font-family: "TT Norms", sans-serif;
        font-weight: 300;
        letter-spacing: 2px;
        margin-top: 40px;
      }

      p {
        font-family: "TT Norms", sans-serif;
        font-weight: 300;
        letter-spacing: 2px;
        padding: 0 60px;
        margin-bottom: 40px;
      }
    }
  }
}

.row-standard-blocks {
  @include breakpoint(medium){
    width:90%;
    margin: 0 auto;
  }

  &.no-link{
    .link-gallery{
      display:none;
    }
  }

  &.event-planners{
    .row-standard-blocks-main{
      max-width: 1100px;
      .contact-paragraph{
        padding: 0 20px;
        letter-spacing:0.5px;
        line-height:30px;
        margin-bottom:40px;
        font-size:18px;
        @include breakpoint(small) {
          padding: 0 20px;
        }
      }
    }
  }
  .row-standard-blocks-main {
    h4 {
      font-family: "TT Norms", sans-serif;
      font-weight: 300;
      letter-spacing: 5px;
      margin-top: 30px;
      font-size: 18px;

      &.cursive{
        font-family:renata,sans-serif;
        font-weight:400;
        letter-spacing: 0;
        font-size:26px;
      }
    }
  }
  &.image-pod {
    .pod-image-wrap{
      max-width:417px;
      margin:30px auto;
    }

    h3 {
      font-weight: 300;
      letter-spacing: 10px;
      padding-bottom: 50px;
      font-size:30px;
    }

    .pod-content-wrapper {
      background: rgba($stanley-iron, 0.25);
      padding: 40px 28px 0;
      position: relative;

      @include breakpoint(small) {
        margin-bottom: 30px;
      }

      h5 {
        font-weight: 500;
        letter-spacing: 2px;
        font-size:20px;
        line-height: 26px;
        margin-bottom:25px;

        a{
          color:#0a0a0a;
        }

        &::after{
          display:none;
        }
      }

      p {
        font-family: "TT Norms", sans-serif;
        letter-spacing: 0;
        text-transform: none;
        margin-bottom: 0;
        font-size:16px;
        line-height:28px;
        min-height:135px;
      }

      .bottom-wrapper {
        padding-bottom:40px;

        h4 {
          margin-top:0;
          margin-bottom: 0;
          position: relative;
          display: inline-block;
          &.go-red{
            color:#94161A;
            transition:all 0.3s;
            &::before {
              background:#94161A;
            }
            &::after{
              background:#94161A;
            }
            a{color:#94161A;}
          }
         
          a{
            color:#000;
            transition:all 0.3s;
           
          }
    
          &::before {
            content: "";
            position: absolute;
            height: 1px;
            background: #000;
            top: 40%;
            left: -70px;
            width: 60px;
            opacity: .5;
          }
        
          &::after {
            content: "";
            position: absolute;
            height: 1px;
            background: #000;
            top: 40%;
            right: -70px;
            width: 60px;
            opacity: .5;
          }
        }
      }
    }
  }

  &.image-with-text {
    h3 {
      font-family: renata, sans-serif;
      text-transform: none;
      font-weight: 400;
      margin-bottom: 0;
    }
    h2 {
      font-weight: 300;
      letter-spacing: 5px;
    }
    
    h5{
      letter-spacing: 2px;
      line-height: 2.25em;
      font-weight: 300;
      font-size: 18px;
      color: #636363;
      @include breakpoint(small){
        font-size:16px;
      }
    }

    p{
      letter-spacing: 2px;
      line-height: 30px;
      font-weight: 300;
      font-size: 18px;
      color: #636363;
  
    }

 
    .row-standard-blocks-main {
      h4 {
        font-family: "TT Norms", sans-serif;
        font-weight: 300;
        letter-spacing: 5px;
        margin-top: 40px;
        font-size:26px;
      }

      p {
        margin-bottom: 40px;
        letter-spacing: 1px;
        padding: 0 60px;
        font-family:"TT Norms", sans-serif;
     
        @include breakpoint(small) {
          padding: 0 20px;
        }
      }
    
     
    }
  }
  &.history-gallery{
    .history-text{
      max-width:730px;
      width:90%;
      display:inline-block;
      margin:0 auto -80px;
      border:1px solid rgba(0,0,0, 0.2);
     
      position:relative;
      z-index:1;
      @include breakpoint(small){
       margin-bottom:-40px;
      }
      &:not(:first-child){
        margin-top:-80px;
        @include breakpoint(small){
          margin-top:-40px;
         }
      }
      > div{
        margin:10px;
        border:1px solid rgba(0,0,0, 0.2);
        padding:50px 90px;
        background-color:#fff;

        @include breakpoint(small){
          padding:25px;
        }
        h2{
          margin-bottom:0;
        }
        h5{
          letter-spacing: 2px;
          line-height: 2.25em;
          font-weight: 300;
          font-size: 18px;
          color: #636363;

          @include breakpoint(small){
            font-size:16px;
            line-height:30px;
          }

          p{
            padding: 0 0 0 0;
            line-height: 2.25em;
            
          }

         
        }
      }

      
    }
  }
  &.cell-padding-normal{
    .cell{
      padding:40px 0;
    }
  }
 
  h2{
    font-weight: 300;
    font-size: 40px;
    letter-spacing: 5px;

    @include breakpoint(small){
      font-size:36px;
    }
  }
  h3{
    margin-bottom:0;
    @include breakpoint(small){
      font-size:26px;
    }
  }

&.job-positions{
  // positions
  .job-position{
    padding:7px;
    border:1px solid #ccc;
    max-width:970px;
    margin: 0 auto 78px;
    position: relative;

  
  
    .job-position-inner{
      background-color:#fff;
      border:1px solid #ccc;
      padding:45px 78px 0;
      @include breakpoint(small){
        padding:45px 28px 0;
      }
      h4{
        margin:0 0 45px;
        font-size:26px;
        letter-spacing: 5px;
        @include breakpoint(small){
          font-size:22px;
          line-height:28px;
          margin: 0 0 25px;
        }
      }

      p{
        font-size:20px;
        line-height:35px;
        letter-spacing: 0.5px;
        margin-bottom:50px;
        @include breakpoint(small){
          font-size:16px;
          line-height:28px;
        }
      }
     
    }
  }
  .cell:nth-child(odd){
    .job-position{
      &::before{
        content: '';
        display:block;
        position:absolute;
        left:-125px;
        top:50px;
        background:url(/wp-content/uploads/2020/01/job-texture1.png) no-repeat left center;
        width: 135px;
        height:270px;
        z-index: -1;
      }
      &::after{
        content: '';
        display:block;
        position:absolute;
        bottom:-60px;
        right:20px;
        background:url(/wp-content/uploads/2020/01/job-texture2.png) no-repeat center bottom;
        width: 370px;
        height:70px;
        z-index: -1;
      }
    }
  }
  .cell:nth-child(even){
    .job-position{
      &::before{
        content: '';
        display:block;
        position:absolute;
        right:-125px;
        top:50px;
        background:url(/wp-content/uploads/2020/01/job-texture1.png) no-repeat left center;
        width: 135px;
        height:270px;
        z-index: -1;
        transform: rotate(-180deg) scaleY(-1);
      }
      &::after{
        content: '';
        display:block;
        position:absolute;
        bottom:-60px;
        left:20px;
        background:url(/wp-content/uploads/2020/01/job-texture2.png) no-repeat center bottom;
        width: 370px;
        height:70px;
        z-index: -1;
        transform:  scaleX(-1);
      }
    }
     
    
  }
  
}
  
}

.fancybox-content{
  margin: 0 50px;
}

