.row-gallery {
  max-width:1140px;
  margin: 0 auto 0;
  position:relative;
  
 


  @include breakpoint(medium) {
    width: 90%;
    .grid-item {
      width: calc(50% - 5px);
      &.hidden-item {
        display: none;
      }
    }
  }
  .gallery-link{
    position:relative;
    display: block;
    margin-bottom:5px;
    &::before{
      content:"";
      display:block;
      position:absolute;
      width:100%;
      height:100%;
      text-align:center;
      left:0;
      top:0;
      z-index:1;
      background-color:rgba(0,0,0,0.5);
      opacity:0;
      transition:all 0.6s;
      background-image:url(/wp-content/uploads/2020/01/expandicon.png);
      background-repeat:no-repeat;
      background-position: center;
      background-size:28px 28px;
    }
    &:hover{
      &::before{
        opacity:1;
        transition:all 0.6s;
      }
    }
  }
  
   
  .grid-item { 
    width:calc(33% - 5px); 
    max-width: 377px;

    @include breakpoint(small){
      width:calc(50% - 5px);
    }
    &.hidden-item{
      height:1px;
      width:100%;
      a{
        display:none;
        &::before{
          display:none;
        }
      }
    }
   }
  .grid-item--width2 { width: 400px; }

}

.fancybox-content {
  padding: 0;
  background-color: transparent;
  overflow: visible;
  box-sizing: content-box;

  .button-previous,
  .button-next {
    position: absolute;
    top: 50%;
    width: 57px;
    height: 57px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translateY(-50%) scale(1);
    transition: transform 0.3s;
    display: none;
    opacity: 1;
    &:hover {
      transform: translateY(-50%) scale(1.2);
      transition: transform 0.3s;
    }
  }
  .button-previous {
    background-image: url(/wp-content/uploads/2020/01/fancybox-left.png);
    left: -28px;
  }
  .button-next {
    background-image: url(/wp-content/uploads/2020/01/fancybox-right.png);
    right: -28px;
  }
}
.fancybox-caption.caption--image {
  width: 100%;
  bottom: 0;
  padding: 10px 0;
  color: #fff;
  transform: translateY(100%);
  background: none;
  animation: fadeCaption 0.7s ease forwards;
  text-align: left;

  .fancybox-caption__body {
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    .fancybox-pagination {
      white-space: nowrap;
    }
  }
}

.fancybox-inner > .fancybox-caption {
  display: none;
}
.fancybox-navigation {
  display: none;
}

@keyframes fadeCaption {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
