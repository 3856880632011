section.header {
  color: #fff;
}

.header-separator-line {
  display: grid;
  grid-template-columns: 1fr 112px 1fr;
  position: absolute;
  width: 100%;
  z-index: 2;
  top:160px;
  
  &.fixed-line{
    position: fixed;
    top:100px;
    left:0;
    .separator-line-center{
      height:0;
      transition: height 0.5s;
    }
  }

  .separator-line-left {
    border-top: 1px solid $stanley-white;
    margin-right: -2px;
    margin-top: 7px;
  }
  .separator-line-center {
    margin-left: 2px;
    margin-top: 7px;
    border-left: 1px solid $stanley-white;
    border-right: 1px solid $stanley-white;
    border-bottom: 1px solid $stanley-white;
    height: 23px;
    transition: height 0.5s;
  }
  .separator-line-right {
    border-top: 1px solid $stanley-white;
    margin-top: 7px;
  }
}

#menu-header-menu-top li:last-child {
  padding-left: 20px;
 
  @include breakpoint(medium){
    display:none;
  }
}

.header-social-icons img {
  margin-bottom: 5px;
}
