

.contact-form-wrapper{
	background-size: cover;
	background-position:top center;
	position:relative;

	.row-standard-header-row{
		.width-80{
			width:80%;
			margin: 0 auto;
		}
	}
	&::before{
		content:"";
		display:block;
		background: top/cover url(/wp-content/uploads/2020/01/wedding-overlay-top.png) no-repeat;
		height: 120px;
		width:100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}

	.grid-container{
		padding:50px 0 80px;

		.row-standard-header-row{
			padding-top:66px;

			h3.cursive{
				letter-spacing: 0;
			}

			h5{
				@include breakpoint(small){
					font-size:16px;
				}
			}
		}

		label{
			text-align:left;
		}
	}
}
.wpcf7-form{
	max-width:710px;
	margin: 30px auto 0;
	@include breakpoint(small){
		margin: 0 auto 0;
	}
}
.wpcf7{
	width: 95%;
	margin: 0 auto;
	@include breakpoint(small){
		padding-top:40px;
	}
	input, 
	textarea
	{
		box-shadow: none;
		
		padding: 25px 18px;

	}
	select{
		box-shadow: none;
		text-indent: 18px;
		color:#000;
		font-family: "TT Norms", sans-serif;
		font-variant-ligatures: none;
		font-weight:300;
		padding:16px 0;
		height:auto;
		padding-right: 1.5rem;
		font-size:18px !important;
	}
	label{
		padding-bottom:15px;
	}
	label,
	input::placeholder,
	textarea::placeholder{
		color:#000;
		font-family: "TT Norms", sans-serif;
		font-variant-ligatures: none;
		font-weight:300;
		font-size:18px;

		@include breakpoint(small){
			font-size:16px;
		}
		
	}

	.contact-form-btn-wrap{
		text-align: center;
		margin: 40px auto 0;
		
		.btn-lines-wrapper{
			&:hover{
				background:transparent;
			}
			.contact-form-btn{
				padding: 12px 30px;
				display: inline-block;
				border: 1px solid #000;
				text-transform: uppercase;
				color: #000;
				font-size: 14px;
				font-family: "TT Norms",sans-serif;
				letter-spacing: 2px;
			
				-webkit-transition: all .2s ease-in-out;
				transition: all .2s ease-in-out;
				&:hover{
					background: #fff;
				}
			}
		}
		
		
	
	}

	.ajax-loader{
		position:absolute;
		right: -20px;
		top: 50%;
		transform:translateY(-50%);
	}

	input[type="submit"]{
		padding: 0 0 0 0;
		border:none;
		outline:none;
	
		display: inline-block;

		text-transform: uppercase;
		color: #000;
		font-size: 14px;
		font-family: "TT Norms",sans-serif;
		letter-spacing: 2px;
		background: transparent;
		transition: all .2s ease-in-out;
	
	}
}

.contact-map{
	background:url(/wp-content/uploads/2020/01/contact-bg2.png) no-repeat;
	background-size:100%;
	background-position:left bottom;
	position: relative;
	margin-bottom:70px;
	@include breakpoint(small){
		margin-bottom:0;
		padding-bottom:40px;
		
	}
	.contact-map-paragraph{
		max-width:360px;
		margin:48px auto 0;
	}
	&::after{
		content:"";
		display:block;
		width:100%;
		height:800px;
		position:absolute;
		background:url(/wp-content/uploads/2020/01/contact-bg3.png) no-repeat;
		background-size: 100%;
   		background-position: left bottom;
		left:0;
		bottom:0;
	}
}

.google-maps-wrapper{
	position:relative;

	.google-maps-outer{
		border:1px solid #fff;
		margin-top:90px;
		display:inline-block;

		@include breakpoint(small){
			margin-top:50px;
		}
	}
	.google-maps-inner{
		margin:5px;
		border:5px solid #fff;
		display:inline-block;
		width:800px;
		height:540px;
	
		@include breakpoint(medium){
			width:600px;
			height:440px;
		}
		@include breakpoint(small){
			width:320px;
			height:240px;
		}
		#google-map{
			width:100%;
			height:100%;
		}
	}
	
}