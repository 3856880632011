.footer {
  background: $stanley-grey;
  padding-top: 11px;

  .footer-overlap {
    background: top center / cover url(/wp-content/themes/tourlabs-stanley/assets/images/footer-background-overlap.png) no-repeat;
    height: 73px;
    margin-top: -84px;
  }

  .footer-lines {
    height: 7px;
    width: 100%;
    border-top: 1px solid $stanley-white;
    border-bottom: 1px solid $stanley-white;
    opacity: 0.3;
  }

  ul {
    list-style-type: none;
    display: inline-block;
    margin-left:0;
    

    &#menu-footer-menu {
      margin-top: 25px;

      @include breakpoint(small){
        margin-top:0;
        margin-left:0;
        margin-bottom:0;
        display:flex;
        flex-wrap:wrap;
        align-items:center;
        justify-content:space-between;
      }

      li {
        a {
          padding: 10px 20px;
          display: inline-block;
          color: $stanley-white;
          text-transform: uppercase;
          font-size: 16px;
          font-family: "TT Norms", sans-serif;
          letter-spacing: 2px;
          @include breakpoint(small){
            font-size:14px;
            padding:5px 10px;
          }
        }
      }
    }

    li {
      display: inline-block;

      a {
        color: $stanley-black;
      }
    }
  }

  .footer-content {
    margin: 30px auto 50px;
   
    letter-spacing: 2px;
    max-width: 80rem;
    

    .footer-logo-flex{
      display:flex;
      > div{
        margin-left:30px;
        @include breakpoint(small){
          margin-left:0;
          margin-top:30px;
        }
      }

      @include breakpoint(small){
        display:block;
        .footer-logo-link{
          margin: 0 auto;
        }
       
      }
    }

    @include breakpoint(small) {
      text-align: center;
      padding: 0 20px;
    }

 

    h5 {
      color: $stanley-white;
      font-size: 14px;

      &::after {
        content: "";
        display: block;
        height: 1px;
        background: $stanley-white;
        width: 43px;
        margin-top: 5px;

        @include breakpoint(small) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    p {
      font-size: 14px;
      font-family: "TT Norms", sans-serif;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      @include breakpoint(small){
        font-size:12px;
      }
      a {
        transition: color 0.5s ease-in-out;
        color: $stanley-black;
       
        &:hover {
          color: $stanley-lightgrey;
        }

        @include breakpoint(small){
          font-size:12px;
        }
       
      }
    }

    .btn-footer-inquiries-wrapper {
      border: 1px solid $stanley-black;
      padding: 5px;
      display: inline-block;
      margin-bottom: 50px;

      &:hover {
        .btn-footer-inquiries {
          background: $stanley-white;
        }  
      }

      .btn-footer-inquiries {
        padding: 12px 60px;
        display: inline-block;
        border: 1px solid $stanley-black;
        text-transform: uppercase;
        color: $stanley-black;
        font-size: 14px;
        font-family: "TT Norms", sans-serif;
        letter-spacing: 2px;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .footer-copyright {
    border-top: 1px solid rgba(255,255,255,0.3);

    .copyright-wrapper {
      padding: 24px 0;
      color: $stanley-white;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 12px;
    }
  }
}

.footer-content{
  display:flex;
  flex-wrap:wrap;

  max-width:1100px;
  margin: 30px auto;
  justify-content: center;

  .footer-logo{
    min-width:44px;
    margin-right: 30px;
    .footer-logo-link{
      display:block;
      .cls-1 {
        fill: #fff;
        fill-rule: evenodd; 
        transition:fill 0.5s;

        &:hover{
          fill: #94171c;
          transition:fill 0.5s;
        }
      }
     
    }
  }
  section:nth-child(2){
    margin-right:75px;
    @include breakpoint(medium){
      margin-right:30px;
    }
    @include breakpoint(small){
      margin-right:0;
    }
  }

  section:nth-child(3){
    margin-right:75px;
    @include breakpoint(medium){
      margin-right:0;
    }
 
  }

}



@media only screen and (max-width: 1245px) {
  .footer-content{
    .show-for-large{
      display:none;
    }
    .hide-for-large{
      display:block !important;
    }
  }

}

@media only screen and (max-width: 900px) {
 .footer-logo{
   display:none;
 }
}
